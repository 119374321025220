export enum IntegrationProvider {
    AUTO_DESKTOP = `AutoDesk`,
    WHEELER_DELTA = `WheelerDelta`,
    AUNSBJERG_API = `AunsbjergAPI`,
    AUTO_DESK_API = `AutoDeskApi`,
    CARGATE_FTP = `CargateFTP`,
    CARWEB_API = `CarwebApi`,
    AUTO_TELEX_API = `AutoTelexApi`,
    NETWHEELS_API = `NetwheelsApi`,
    BILINFO = `Bilinfo`,
    BILIA_FTP = `Bilia`,
    HEDIN_FTP = `Hedin`,
    HEDIN_AZURE = `Hedin - Azure Blob Storage`,
    BYTBIL_ROBOT = `BytBil`,
    CARWEB = `Carweb`,
    WAYKE_API = `WAYKE_API`,
    CARSWIP_API = `Carswip API`,
    MB_JAPAN = `Mercedes Benz Japan`,
    FAST_BACK = `FastBack`,
}